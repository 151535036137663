/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, shape, string, number, bool, arrayOf, func,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import mbpLogger from 'mbp-logger';

import Slider from 'react-slick';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import customBreakpoints from '../../helpers/customBreakpoints';
import DesktopMobile from '../../helpers/DesktopMobile/DesktopMobile';
import ResponsiveImage from '../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { trackEvent as track } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({
    root: {
        marginTop: '20px',
        background: '#f5f5f5',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    rootWithGrid: {
        background: '#f5f5f5',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    header: {
        width: '100%',
        textAlign: 'center',
        fontSize: '30px',
        fontWeight: '500',
        fontFamily: 'PlayfairDisplayBold, serif',
        color: '#000',
        letterSpacing: '1px',
        marginBottom: '40px',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            fontSize: '16px',
            letterSpacing: 0.25,
            marginBottom: '20px',
        },
    },
    container: {
        display: 'flex',
        overflow: 'hidden',
        maxWidth: '1440px',
        margin: '0 auto',
        paddingLeft: 0,
    },
    reviewBox: {
        borderRight: '1px solid #000',
        display: 'flex',
        textAlign: 'center',
        flex: '25%',
        padding: '7px',
        outline: 'none',
        '&:last-of-type': {
            borderRight: 'none',
        },
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            width: '25%',
            padding: '0 2%',
        },
    },
    linkWrapper: {
        textDecoration: 'none',
        color: '#000',
        cursor: 'pointer',
    },
    reviewImage: {
        marginBottom: '30px',
        width: '100px',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            width: '60%',
            marginBottom: '10px',
        },
    },
    starContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    star: {
        fontSize: '150%',
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        padding: '0 2px',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            fontSize: '75%',
        },
    },
    reviewTitle: {
        fontWeight: 'bold',
        fontSize: '20px',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            fontSize: '14px',
            margin: '10px auto',
        },
    },
    reviewCopy: {
        fontSize: '18px',
        padding: '0 10px',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            fontSize: '12px',
            margin: '10px auto',
            padding: 0,
        },
    },
    reviewName: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: 0,
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            fontSize: '14px',
            margin: '10px auto',
        },
    },
    reviewAddress: {
        fontSize: '16px',
        fontStyle: 'italic',
        [theme.breakpoints.down(customBreakpoints.tabPortrait)]: {
            fontSize: '14px',
            margin: 0,
        },
    },
    poweredByContainer: {
        margin: '2em auto',
        color: '#1472E6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    poweredByContainerWithGrid: {
        margin: '2em auto 0',
    },
    yotpoIcon: {
        marginLeft: '5px',
        height: '30px',
    },
    mobileOuterContainer: {
        width: '100%',
    },
    slider: {
        '& img': {
            margin: '0 auto 0',
            maxWidth: '100%',
            '&:hover': {
                transform: 'scale(1.1)',
            },
        },
    },
    sliderContainer: {
        position: 'relative',
        width: '100%',
        '& .slick-list': {
            overflow: 'hidden',
            margin: '0 auto',
            width: '95%',
        },
        '& .slick-slide': {
            display: 'inline-block',
            padding: 3,
            verticalAlign: 'top',
        },
        '& .slick-prev, & .slick-next': {
            transform: 'translate(0,-50%)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            fontSize: 0,
            lineHeight: 0,
            top: '42%',
            width: 16,
            height: 30,
            position: 'absolute',
            background: 'transparent',
        },
        '& .slick-prev': {
            left: 2,
        },
        '& .slick-next': {
            right: 0,
        },
    },
});

const UserReviews = ({
    classes, data: { title = '', review_entries = [] }, trackEvent, history,
}) => {
    const seed = useUIDSeed();
    const getStars = (star_rating) => {
        /**
         * @description text
         * @param {type} type - text
         * @return {type} type - text
         */

        const star = <div className={classes.star}>&#9733;</div>;
        switch (star_rating) {
            case 5:
                return <div className={classes.starContainer}>{star}{star}{star}{star}{star}</div>;
            case 4:
                return <div className={classes.starContainer}>{star}{star}{star}{star}</div>;
            case 3:
                return <div className={classes.starContainer}>{star}{star}{star}</div>;
            case 2:
                return <div className={classes.starContainer}>{star}{star}</div>;
            case 1:
                return <div className={classes.starContainer}>{star}</div>;
            default: return null;
        }
    };

    const trackEventAndPush = (product_link, tracking_event_action, tracking_event_category, tracking_event_label) => {
        trackEvent({
            eventCategory: tracking_event_category,
            eventAction: tracking_event_action,
            eventLabel: tracking_event_label,
        });
        history.push(product_link?.href);
    };

    const renderReviews = (review) => {
        const {
            image = {}, star_rating = 5, product_link, copy = '', sender = '', sender_address = '', tracking_event_action = '', tracking_event_category = '', tracking_event_label = '',
        } = review;
        return (
            <li className={classes.reviewBox} key={seed(review)}>
                <div
                    className={classes.linkWrapper}
                    onClick={() => {
                        trackEventAndPush(product_link, tracking_event_action, tracking_event_category, tracking_event_label);
                    }}
                    onKeyDown={() => {
                        trackEventAndPush(product_link, tracking_event_action, tracking_event_category, tracking_event_label);
                    }}
                    role="button"
                    tabIndex="0"
                >
                    <ResponsiveImage className={classes.reviewImage} path={image?.url} alt={product_link?.title} params={{ desktop: 'quality=100', tablet: 'quality=100', mobile: 'quality=100' }} />
                    {getStars(star_rating)}
                    <p className={classes.reviewTitle}>{review?.title}</p>
                    <p className={classes.reviewCopy}>{copy}</p>
                    <p className={classes.reviewName}>{sender}</p>
                    <p className={classes.reviewAddress}>{sender_address}</p>
                </div>
            </li>
        );
    };
    if (review_entries?.length > 0) {
        const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
        return (
            <div className={ffHasHomepageGridStructure ? classes.rootWithGrid : classes.root}>
                <h3 class="header3 font-decorative strong u-center u-margin-top-bottom-32">{title}</h3>
                <ul className={classes.container}>
                    <DesktopMobile
                        desktop={() => (
                            <>
                                {review_entries.map((review) => (
                                    renderReviews(review)
                                ))}
                            </>
                        )}
                        mobile={() => (
                            <>
                                <div className={classes.mobileOuterContainer}>
                                    <Slider
                                        dots={false}
                                        speed={800}
                                        infinite
                                        slidesToShow={2}
                                        slidesToScroll={1}
                                        nextArrow={<ArrowForwardIosIcon />}
                                        prevArrow={<ArrowBackIosIcon />}
                                        className={`carousel-container ${classes.sliderContainer}`}
                                    >
                                        {review_entries.map((review) => (
                                            renderReviews(review)
                                        ))}
                                    </Slider>
                                </div>
                            </>
                        )}
                        breakpoint={1024}
                        ignoreHover
                    />
                </ul>

                <p className={`${classes.poweredByContainer} ${ffHasHomepageGridStructure && classes.poweredByContainerWithGrid}`}>
                    <span>Powered by</span>
                    <ResponsiveImage className={classes.yotpoIcon} path="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/bltcf637a6e16efdb8e/5ea9acbed4b10d15d3e8cfb3/yotpo-logo.png" alt="yotpo" params={{ desktop: 'quality=100', mobile: 'quality=100' }} />
                </p>
            </div>

        );
    }
    return mbpLogger.logError({
        message: 'No entries provided for component User Reviews',
        appName: process.env.npm_package_name,
        module: 'mbp-pwa-ui',
    });
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

UserReviews.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    data: shape({
        title: string,
        review_entries: arrayOf(shape({
            copy: string,
            image: shape({
                content_type: string,
                file_size: string,
                filename: string,
                is_dir: bool,
                title: string,
                uid: string,
                url: string,
            }),
            product_link: shape({
                title: string,
                href: string,
            }),
            star_rating: number,
            sender: string,
            sender_address: string,
            title: string,
        }).isRequired),
        tracking_event_action: string.isRequired,
        tracking_event_category: string.isRequired,
        tracking_event_label: string.isRequired,
    }).isRequired,
    trackEvent: func.isRequired,
};

export default withRouter(connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(UserReviews)));
